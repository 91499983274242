import {
  WITHDRAW_STARTED,
  WITHDRAW_SUCCESSFUL,
  WITHDRAW_FAILED,
  VERIFY_STARTED,
  VERIFY_SUCCESSFUL,
  VERIFY_FAILED,
  SHOW_MODAL,
  UPDATE_USER_BALANCE,
} from "../types";

import {
  getUserDetails,
  processUserWithdrawal,
  shopWithdrawal,
  verifyRequest,
} from "../../Services/apis";
import { ErrorPopUp, SuccessPopUp } from "../../Utils/toastify";

export const postWithdrawStarted = () => {
  return {
    type: WITHDRAW_STARTED,
  };
};

export const postWithdrawSuccess = (payload) => {
  return {
    type: SHOW_MODAL,
    payload: null,
  };
};

export const postWithdrawFailed = (payload) => {
  return {
    type: WITHDRAW_FAILED,
    payload,
  };
};

export const verifyStarted = () => {
  return {
    type: VERIFY_STARTED,
  };
};

export const verifySuccess = (payload) => {
  return {
    type: VERIFY_SUCCESSFUL,
    payload,
  };
};

export const verifyFailed = (payload) => {
  return {
    type: VERIFY_FAILED,
    payload,
  };
};

export const updateUserBalance = (payload) => {
  return {
    type: UPDATE_USER_BALANCE,
    payload,
  };
};

export const getDetails = async (dispatch) => {
  getUserDetails()
    .then((res) => {
      console.log(res);
      if (res.success) {
        dispatch(updateUserBalance(res?.data?.availableBalance));
      } else {
        ErrorPopUp(res?.message);
      }
    })
    .catch((err) => {
      if (err) {
        ErrorPopUp("Internal server error!");
      }
    });
};

export const createWithdraw = (payload) => async (dispatch) => {
  try {
    dispatch(postWithdrawStarted());
    const response = await processUserWithdrawal(payload);
    const { data } = response;
    // if (data) {
    SuccessPopUp(response?.message);
    dispatch(getDetails());
    console.log(data, response);
    // }
    return dispatch(postWithdrawSuccess(response.balance));
  } catch (error) {
    ErrorPopUp("Error occured");
    return dispatch(postWithdrawFailed(error.response.data));
  }
};

export const verifyWithdraw = (payload) => async (dispatch) => {
  try {
    dispatch(verifyStarted());
    const response = await verifyRequest(payload);
    const { data } = response;

    SuccessPopUp(response?.message);
    return dispatch(verifySuccess(response));
  } catch (error) {
    ErrorPopUp("Error occured");
    return dispatch(verifyFailed(error.response.data));
  }
};

export const approveWithdraw = (payload) => async (dispatch) => {
  try {
    dispatch(postWithdrawStarted());
    const response = await verifyRequest(payload);
    const { data } = response;

    SuccessPopUp(response?.message);
    return dispatch(postWithdrawSuccess(response.balance));
  } catch (error) {
    ErrorPopUp("Error occured");
    return dispatch(postWithdrawFailed(error.response.data));
  }
};
