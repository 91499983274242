import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  CANCEL_BET,
  CLEAR_BET,
  CONFIRM_BET,
  LOADING,
  SET_BET_PLACED,
  SHOW_MODAL,
} from "../Redux/types";
import moment from "moment";

import { MdOutlineClose } from "react-icons/md";
import { createID, printTicket } from "../Utils/couponHelpers";
import { toast } from "react-toastify";
import {
  findBooking,
  getActiveGames,
  getAgentBetList,
  getBetList,
  getTransactions,
  validateGames,
} from "../Services/apis";
import { formatDate, formatNumber } from "../Utils/helpers";
import GameAddedModal from "./Modal/GameAdded";
import _, { forEach } from "lodash";
import {
  addToCoupon,
  addToCoupon1x,
  fastAdd,
  placeBet,
  updateWinnings,
} from "../Redux/actions";
import { ErrorPopUp, SuccessPopUp } from "../Utils/toastify";

const Bet = () => {
  const { user } = useSelector((state) => state.auth);
  const [showMarket, setShowMarket] = useState(false);
  const [hideMarketInput, setHideMarketInput] = useState(false);
  const [showSelectedNumber, setShowNumber] = useState(false);
  const [code, setCode] = useState("");
  const [newPayload, setPayload] = useState([]);
  const [selections, setSelections] = useState([]);
  const [showPreview, setShowPreview] = useState(false);
  const [value, setValue] = useState({ number: null, market: "" });
  const [markets, setMarkets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [bets, setBets] = useState([]);
  const [betType, setBetType] = useState("");
  const [games, setGames] = useState([]);
  const [valueNumber, setValueNumber] = useState();
  const [valueMarket, setValueMarket] = useState();
  const [transactions, setTransactions] = useState([]);
  const [, setAcceptedSelections] = useState([]);
  const [filterData, setFilterData] = useState({
    from: moment().subtract(1, "w").toDate(),
    to: moment().toDate(),
    period: "",
    status: "",
    betslip_id: "",
    page_size: 20,
  });
  const { coupon, todaysBets, betPlaced, confirm } = useSelector(
    (state) => state.couponData
  );

  const amounts = process.env.REACT_APP_FAST_ADD_AMOUNTS.split(",");
  const dispatch = useDispatch();
  const clientId = process.env.REACT_APP_CLIENT_ID;

  const numberInputRef = useRef(null);
  const marketInputRef = useRef(null);
  const stakeInputRef = useRef(null);
  const printButtonRef = useRef(null);

  const getData = () => {
    getActiveGames().then((response) => {
      const { data, success } = response;
      if (success) {
        setGames(data.games);
        setMarkets(data.activeMarkets.sort((a, b) => a.marketID - b.marketID));
      } else {
        setGames([]);
        setMarkets([]);
      }
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        getData();
      } catch (error) {}
    };

    fetchData(); // Initial fetch

    const interval = setInterval(fetchData, 15000); // Adjust interval as needed

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (!showSelectedNumber && numberInputRef.current) {
      numberInputRef.current.focus();
    }
  }, [showSelectedNumber]);

  useEffect(() => {
    if (showMarket && !hideMarketInput && marketInputRef.current) {
      marketInputRef.current.focus();
    }
    // else {
    //   stakeInputRef.current.focus();
    // }
  }, [showMarket, hideMarketInput]);

  const handleBlur = (event) => {
    if (event.key === "Enter") {
      if (!valueNumber) {
        stakeInputRef.current.focus();
      } else {
        const marketExists = selections.some(
          (item) => item.gameID === valueNumber
        );

        if (marketExists) {
          // toast.error(
          //   `Game code "${valueNumber}" already exists in selections.`
          // );
          dispatch({
            type: SHOW_MODAL,
            payload: {
              open: true,
              title: "",
              component: <GameAddedModal message={"Game already added"} />,
            },
          });
          event.preventDefault();
          return; // Exit the function to prevent adding a duplicate
        }

        setShowNumber(true);
        setShowMarket(true);
        setHideMarketInput(false);
      }
    }
  };

  const addToSelections = (event) => {
    let newData = [...selections];
    let payload = [...newPayload];
    if (event.key === "Enter") {
      const filteredData = markets?.filter((item) =>
        item?.code.includes(valueMarket)
      );

      newData = [
        ...newData,
        {
          code: filteredData[0].name,
          outcomeID: filteredData[0].outcomeID,
          gameID: valueNumber,
          marketID: filteredData[0].marketID,
        },
      ];

      payload = [
        ...payload,
        {
          outcomeID: filteredData[0].outcomeID,
          gameID: valueNumber,
          marketID: filteredData[0].marketID,
          specifier: filteredData[0].specifier,
        },
      ];

      setSelections(newData);
      setPayload(payload);

      setShowNumber(false);
      setHideMarketInput(true);
      // setSelected([...selected, value]);
      setValueNumber();
      setValueMarket();
    }
  };

  const stakeHandleBlur = (event) => {
    // event.preventDefault();
    if (event.key === "Enter") {
      // dispatch(fastAdd(Number(stake)));
      printButtonRef.current.click();
    }
  };

  const removeSelection = (_index_) => {
    setSelections((pre) => {
      return pre.filter((item, index) => index !== _index_);
    });
  };

  const onHandleChange = (event) => {
    event.preventDefault();
    setValueNumber(event.target.value);
  };

  const onHandleChangeMarket = (event) => {
    event.preventDefault();
    setValueMarket(event.target.value);

    setValue({ ...value, number: valueNumber, market: valueMarket });
  };

  useEffect(() => {
    const resp = () => {
      getTransactions({ clientId }, 1, 10)
        .then((res) => {
          if (res.success && res.data) {
            setTransactions(res.data);
          }
        })
        .catch((err) => console.log(err.message));
    };

    resp();
  }, []);

  const updateStake = (event) => {
    dispatch(updateWinnings(event.target.value));
  };

  const confirmBet = async (e) => {
    if (betType === "rebet" || betType === "previewed") {
      dispatch(placeBet(e, "bet", resetBet));
    } else {
      setShowPreview(true);
      try {
        const response = await validateGames(newPayload);
        console.log(response);
        if (response?.success) {
          updateCouponData(response?.data?.accepted, "bet");
          if (response?.data?.rejected?.length > 1) {
          } else {
            dispatch(placeBet(e, "bet", resetBet));
          }
        } else {
          ErrorPopUp(response?.message);
        }
        return response?.data?.accepted;
      } catch (err) {
        ErrorPopUp(err?.response?.message);
      }
    }
    numberInputRef.current.focus();
  };

  const preview = async () => {
    setBetType("previewed");
    setShowPreview(true);
    try {
      const response = await validateGames(newPayload);

      if (response?.success) {
        setAcceptedSelections(response?.data?.accepted);
        updateCouponData(response?.data?.accepted, "bet");
        if (response?.data?.rejected?.length > 1) {
        }
      } else {
        ErrorPopUp(response?.message);
      }
      return response?.data?.accepted;
    } catch (err) {
      ErrorPopUp(err?.response?.message);
    }
  };

  const resetBet = () => {
    setShowPreview(false);
    setSelections([]);
    setPayload([]);
    setCode("");
    dispatch({ type: CANCEL_BET });
    setBetType("");
  };

  const updateCouponData = (data, type) => {
    if (data?.length > 0) {
      data?.forEach((element, index, array) => {
        const id = createID(
          type === "bet" ? element.matchID : element.matchId,
          type === "bet" ? element.marketID : element.marketId,
          type === "bet" ? element.outcomeName : element.outcomeName,
          type === "bet" ? element.outcomeID : element.outcomeId
        );
        const ifExisit = coupon?.selections?.some(
          (item) => item.eventName === element?.name || element?.eventName
        );

        if (ifExisit) {
          dispatch({
            type: SHOW_MODAL,
            payload: {
              open: true,
              title: "",
              component: <GameAddedModal message={"Selection already added"} />,
            },
          });
          return;
        } else {
          dispatch(addToCoupon1x(element, id, "prematch", type));
        }
      });
    }
  };

  const fetchBetList = (page) => {
    setLoading(true);
    const data = {
      // type: filterData.type,
      from: moment(filterData.from).startOf("day").format("YYYY-MM-DD HH:mm"),
      to: moment(filterData.to).endOf("day").format("YYYY-MM-DD HH:mm"),
      perPage: filterData.page_size,
      status: filterData.status,
      userId: user?.id,
      clientId: process.env.REACT_APP_CLIENT_ID,
    };

    if (user.role === "Cashier") {
      cashierBetList(data, page);
    } else {
      agentBetList(data, page);
    }
  };

  const cashierBetList = (data, page) => {
    getBetList(data, page)
      .then((res) => {
        if (res.bets) {
          setBets(res.bets);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const agentBetList = (data, page) => {
    getAgentBetList(data, page)
      .then((res) => {
        if (res.data.tickets.length) {
          const { meta, tickets } = res.data;

          setBets(tickets);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchBetList(1);
  }, [betPlaced]);

  const findBetCode = (e) => {
    e.preventDefault();

    const payload = {
      betslipId: code,
      clientId: process.env.REACT_APP_CLIENT_ID,
    };
    findBooking(payload)
      .then((res) => {
        if (res.success) {
          setBetType("rebet");
          SuccessPopUp(res?.message);
          dispatch(updateWinnings(parseFloat(res?.data?.stake)));
          updateCouponData(res?.data?.selections, "rebet");
          setShowPreview(true);
        } else {
          ErrorPopUp(res?.message);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const filterBet = () => {
    let totalBets = bets.filter(
      (item) =>
        moment(item.created)?.format("YYYY-MM-DD") ===
        moment().format("YYYY-MM-DD")
    );

    return totalBets;
  };

  return (
    <div className="">
      <div className="Riquadro">
        <div className="CntSX">
          <div className="CntDX">
            <div id="s_w_PC_PC_panelSquare">
              <div className="RiquadroNews Reg">
                <div className="Cnt">
                  <h4
                    className="p-4 commission-head"
                    style={{
                      margin: "1rem 0",
                      background: "white",
                      color: "black",
                    }}
                  >
                    Ticket barcode
                  </h4>
                </div>
                <div className="cashbook card-x">
                  <div className="x-card">
                    <div>
                      <div className="card-head-x flex">
                        <p>Today</p>
                        <p>{formatDate(new Date(), "MMMM DD, YYYY")}</p>
                      </div>
                      <div className="x-card-body flex">
                        <p>Credit Usage</p>
                        <div>
                          <button className="botton-slim bg-yello">
                            {formatNumber(user?.availableBalance)}
                            &nbsp;
                          </button>
                        </div>
                      </div>
                      <div className="x-card-footer flex">
                        <p>Sales</p>
                        <button className="botton-slim bg-blue">
                          {filterBet()?.length}
                          {"("}
                          {formatNumber(
                            filterBet()?.reduce(
                              (sum, item) => sum + Number(item.stake),
                              0
                            )
                          )}
                          {")"}
                        </button>
                      </div>
                    </div>

                    <div className="shortcut ">
                      <h5 className="p">Shortcuts Help</h5>
                      <div
                        style={{
                          height: "15rem",
                          overflowY: "auto",
                          paddingBlock: "1rem",
                        }}
                      >
                        {markets?.map((item) => (
                          <div className="flex px">
                            <p>{item?.name}</p>
                            <p>{item?.code}</p>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="x-card-second x-card">
                    <form className="head ">
                      <div>
                        <label>Insert Booking Number</label>
                        <div className=" booking-code">
                          <input
                            type="text"
                            // maxlength="20"
                            className="TextBox"
                            value={code}
                            onChange={(e) => setCode(e.target.value)}
                          />
                          <button onClick={(e) => findBetCode(e)}>Load</button>
                        </div>
                      </div>
                      <div className="mb" style={{ marginTop: ".5rem" }}>
                        {showSelectedNumber ? (
                          <div className="flex current-head">
                            <h4>Current Selection</h4>
                            <p className="ml">{valueNumber}</p>
                          </div>
                        ) : (
                          <input
                            placeholder="Selection"
                            type="number"
                            ref={numberInputRef}
                            name="number"
                            value={valueNumber}
                            onChange={(e) => onHandleChange(e)}
                            onKeyDown={handleBlur}
                          />
                        )}
                      </div>
                      {showMarket && (
                        <div>
                          {hideMarketInput ? (
                            <div>
                              <p>{valueMarket}</p>
                            </div>
                          ) : (
                            <input
                              placeholder="Selection"
                              type="text"
                              ref={marketInputRef}
                              name="market"
                              onChange={(e) => onHandleChangeMarket(e)}
                              onKeyDown={addToSelections}
                            />
                          )}
                        </div>
                      )}
                      <h4>Bet Ticket ({selections?.length})</h4>
                    </form>
                    <div>
                      <div>
                        {selections?.map((item, _i_) => (
                          <div
                            style={{
                              justifyContent: "space-between",
                              padding: "0rem 1rem",
                              margin: "0.5rem 0",
                              textAlign: "center",
                              color: "white",
                            }}
                            className="flex bg-yellow "
                          >
                            <div className="flex ">
                              {" "}
                              <p>{item?.gameID}</p>
                              <p className="ml">{item?.code}</p>
                            </div>
                            <div className="flex ">
                              <button
                                onClick={() => removeSelection(_i_)}
                                type="button"
                                style={{
                                  justifySelf: "end",
                                  width: "20px",
                                  height: "20px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  padding: "2px",
                                  backgroundColor: "red",
                                  border: "none",
                                  borderRadius: "100%",
                                  color: "white",
                                }}
                              >
                                <MdOutlineClose fontSize={20} />
                              </button>
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className="flex stake">
                        <h4>Stake</h4>
                        <input
                          className="stake-input"
                          onChange={(event) => updateStake(event)}
                          type="number"
                          ref={stakeInputRef}
                          onKeyDown={stakeHandleBlur}
                          value={coupon?.totalStake}
                        />
                      </div>
                    </div>

                    <div className="flex stake-btn">
                      <button
                        className="bg-red"
                        button="button"
                        onClick={resetBet}
                      >
                        Clear
                      </button>
                      <button className="bg-yellow" onClick={() => preview()}>
                        Preview
                      </button>
                      <button
                        className="bg-green"
                        ref={printButtonRef}
                        type="button"
                        onClick={(e) => {
                          confirmBet(e);
                        }}
                      >
                        Print
                      </button>
                    </div>
                  </div>
                  <div className="x-card x-card-thrid">
                    <div
                      className="card-head-x-bottom"
                      style={{ marginTop: "-.1rem" }}
                    >
                      <div className="head p flex">
                        <p style={{ fontSize: "1rem" }}>Latest Tickets</p>
                        <p>-</p>
                      </div>
                      <div className="body flex p">
                        <h4>Time</h4>
                        <h4>Bets</h4>
                        <h4>Stake</h4>
                      </div>
                      <div>
                        {bets?.slice(0, 5).map((item) => (
                          <div className="body flex p">
                            <h4>
                              {" "}
                              {moment(item?.created).format("YYYY-MM-DD HH:mm")}
                            </h4>
                            <h4
                              style={{
                                marginLeft: "-1.5rem",
                                textAlign: "left",
                              }}
                            >
                              {item?.betslipId}
                            </h4>
                            <h4>{item?.stake}</h4>
                          </div>
                        ))}
                      </div>
                    </div>
                    {showPreview && coupon.selections.length > 0 && (
                      <div
                        style={{
                          background: "white",
                          justifyContent: "space-betwee",
                          padding: ".5rem 1rem",
                          margin: "0.5rem 0",
                          textAlign: "center",
                          boxShadow: "1px 1px 1px grey",
                        }}
                      >
                        <div className="flex">
                          <h4>Preview</h4>
                          <p onClick={() => setShowPreview(false)}>
                            {" "}
                            <MdOutlineClose fontSize={20} />
                          </p>
                        </div>
                        {coupon.selections.map((item, _i_) => (
                          <div className="flex ">
                            {""}
                            <p>{item?.gameId}</p>
                            <p className="ml">{item?.eventName}</p>
                            <p className="ml">{item?.outcomeName}</p>
                            <p className="ml">{item?.odds}</p>
                          </div>
                        ))}
                        <div className="">
                          <span>
                            <strong>Stake:</strong>
                          </span>{" "}
                          <span>{coupon?.totalStake}</span>
                        </div>
                        <div className="" style={{ marginTop: ".4rem" }}>
                          <span>
                            <strong>Odds:</strong>
                          </span>{" "}
                          <span>
                            {formatNumber(
                              coupon.selections.reduce(
                                (total, sel) => total + Number(sel?.odds),
                                0
                              )
                            )}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Bet;
