import React, { Fragment, useCallback, useEffect } from "react";
import { SWRConfig } from "swr";
import { ToastContainer } from "react-toastify";
import "./App.scss";
import { AppHeader } from "./Components/AppHeader";
import Http from "./Utils/Http";
import ErrorBoundary from "./ErrorBoundary";
import { useDispatch, useSelector } from "react-redux";
import { AppFooter } from "./Components/AppFooter";
import Routes from "./Routes/Routes";
import {
  fetchBonusList,
  fetchGlobalVars,
  verifyTransactions,
} from "./Services/apis";
import * as types from "./Redux/types";
import Modal from "./Components/Modal";
import { useLocation, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import VerifyTransaction from "./Components/Modal/VerifyTransactionsModal";
import CashInOutVerifyModal from "./Components/Modal/CashInOutVerifyModal";
import { SHOW_MODAL, CASHIN_DETAILS, CASHOUT_DETAILS } from "./Redux/types";
import { getCashIn, getCashOuts } from "./Services/apis";

function App({ history }) {
  const { isAuthenticated, user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const location = useLocation();
  const router = useHistory();
  const init = useCallback(() => {
    Promise.all([fetchBonusList(), fetchGlobalVars()]).then((res) => {
      dispatch({ type: types.SET_BONUS_LIST, payload: res[0] });
      dispatch({ type: types.SET_GLOBAL_VAR, payload: res[1] });
    });
  }, [dispatch]);

  useEffect(() => {
    init();
  }, [init]);

  useEffect(() => {
    if (user && isAuthenticated && location.pathname !== "/Account/Cash-Book")
      verifyMyTransactions();
  }, [location.pathname, user, isAuthenticated]);

  useEffect(() => {
    if (user && isAuthenticated && location.pathname !== "/Account/Cash-Book") {
      fetchCashin();
      fetchCashout();
    }
  }, [dispatch, SHOW_MODAL, location.pathname]);

  useEffect(() => {
    const isMobileDevice = () => {
      return /iPhone|iPad|iPod|Android|webOS|BlackBerry|Windows Phone/i.test(
        navigator.userAgent
      );
    };

    if (isMobileDevice()) {
      window.location.href = "https://shop.mini.raimax.bet";
    }
  }, []);

  const fetchCashin = async () => {
    const cashIn = await getCashIn();
    if (cashIn.success) {
      // const isPending = cashIn?.data?.filter((item) => item.status === 0);
      // if (isPending.length) {
      //   dispatch({
      //     type: SHOW_MODAL,
      //     payload: {
      //       open: true,
      //       title: "PENDING CASHIN",
      //       component: <CashInOutVerifyModal />,
      //     },
      //   });
      // }
      // dispatch({
      //   type: CASHIN_DETAILS,
      //   payload: {
      //     cashIn: cashIn.data,
      //   },
      // });
    }
  };

  const fetchCashout = async () => {
    const cashout = await getCashOuts();

    if (cashout.success) {
      // const isPending = cashout?.data?.filter((item) => item?.status === 0);
      // if (isPending?.length) {
      //   dispatch({
      //     type: SHOW_MODAL,
      //     payload: {
      //       open: true,
      //       title: "PENDING CASHOUT",
      //       component: <CashInOutVerifyModal />,
      //     },
      //   });
      // }
      // dispatch({
      //   type: CASHOUT_DETAILS,
      //   payload: {
      //     cashout: cashout?.data,
      //   },
      // });
    }
  };

  const verifyMyTransactions = async () => {
    const res = await verifyTransactions();
    if (!res.success) {
      dispatch({
        type: SHOW_MODAL,
        payload: {
          open: true,
          title: "HANDLE REPORT",
          component: <VerifyTransaction title={res.message} />,
        },
      });
      router.push("/Account/Cash-Book");
      // toast.error(res.message, {
      //   position: "top-right",
      //   autoClose: 5000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   theme: "light",
      // });
    }
  };

  return (
    <ErrorBoundary>
      <SWRConfig
        value={{
          fetcher: (url) => Http.get(url).then((res) => res),
          refreshInterval: 15 * 60 * 1000,
          shouldRetryOnError: false,
          revalidateOnFocus: false,
          errorRetryInterval: 0,
          errorRetryCount: 2,
        }}
      >
        <Fragment>
          <AppHeader
            user={user}
            isAuthenticated={isAuthenticated}
            dispatch={dispatch}
          />
          <div className="bannerHome">
            <div className="divMainHome">
              <div id="divMain">
                <div id="divContent">
                  <Routes />
                  <div className="spacer30"></div>
                  <Modal />
                </div>
              </div>
            </div>
          </div>
          <AppFooter />
        </Fragment>
      </SWRConfig>
      <ToastContainer
        position="bottom-right"
        autoClose={10000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
      />
    </ErrorBoundary>
  );
}

export default App;
