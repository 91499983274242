import { LOADING, SHOW_MODAL } from "../../Redux/types";
import { createWithdraw } from "../../Redux/actions/onlineWithdraw";

export const WithdrawalConfirmModal = ({ data, dispatch, user }) => {
  const approve = () => {
    dispatch(
      createWithdraw({
        id: data.requestId,
        role: user?.user?.role,
      })
    );
    dispatch({ type: SHOW_MODAL, payload: null });
  };

  return (
    <div className="withdraw">
      <form className="new-entry">
        <div className="entry-field">
          <label>Customer ID</label>
          <input
            type="text"
            name="opening"
            value={data?.username}
            disabled={true}
          />
        </div>
        <div className="entry-field">
          <label>Withdrawal Amount</label>
          <input
            type="number"
            name="opening"
            value={data?.amount}
            disabled={true}
          />
        </div>
        {/* <div className="entry-field">
        <label>Code</label>
        <input
          type="text"
          name="opening"
          value={inputObject.withdrawCode}
          disabled={true}
        />
      </div> */}
        <div className="entry-field">
          <label>Your Balance</label>
          <input
            type="number"
            name="opening"
            disabled={true}
            value={user?.user?.availableBalance}
          />
        </div>
      </form>
      <div className="expense-btn">
        <input
          type="button"
          name="expenses"
          value="PAY CUSTOMER"
          className="btn-green"
          // disabled={loading}
          onClick={() => approve()}
        />
        <input
          type="button"
          name="expenses"
          value="CANCEL"
          className=" btn-red"
          onClick={() => dispatch({ type: SHOW_MODAL, payload: null })}
        />
      </div>
    </div>
  );
};
