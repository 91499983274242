import axios from "axios";
import { Http } from "../Utils";

export const fetchGlobalVars = () =>
  Http.get(
    `${process.env.REACT_APP_NEW_API}/auth/globalvariables/${process.env.REACT_APP_CLIENT_ID}`
  );

export const fetchBonusList = () =>
  Http.get(`/utilities/bonuslist?section=shop`);

export const getGatewayKeys = (gateway) =>
  Http.get(`utilities/get-gateway-keys/${gateway}`);

export const getEventDetails = (event_id) =>
  Http.get(
    `${process.env.REACT_APP_NEW_API}/sports/match/${event_id}?timeoffset=+2`
  );

export const getActiveGames = () =>
  Http.get(
    `${process.env.REACT_APP_NEW_API}/sports/retail/get-active-games?timeoffset=+2`
  );
export const getSportMenu = (period, start = "", end = "") =>
  Http.get(
    `${process.env.REACT_APP_NEW_API}/sports/menu?period=${period}&start=${start}&end=${end}&timeoffset=+2`
  );
export const getCategories = (period, sid) =>
  Http.get(
    `${process.env.REACT_APP_NEW_API}/sports/categories/${sid}?period=${period}&timeoffset=+2`
  );

export const getTournaments = (period, cid) =>
  Http.get(
    `${process.env.REACT_APP_NEW_API}/sports/tournaments/${cid}?period=${period}&timeoffset=+2`
  );
export const getBanners = () => Http.get(`/sports/banners?banner_type=web`);

export const getTopBets = () => Http.get(`/sports/topbets`);

export const fetchFixturesByDate = (date) =>
  Http.get(`/sports/get-fixtures-by-date?date=${date}&channel=website`);

export const fetchFixturesByDateSport = (date, sport_id) =>
  Http.get(
    `/sports/get-fixtures-by-sport-date?date=${date}&sid=${sport_id}&channel=website`
  );

export const getFixture = (eventId) =>
  Http.get(`${process.env.REACT_APP_NEW_API}sports/get-fixture/${eventId}`);

export const getMatches = (tid) => Http.get(`sports/get-matches/${tid}`);

export const getFixtures = (tid, sid, period, market = "", specifier = "") =>
  Http.get(
    `${process.env.REACT_APP_NEW_API}/sports/retail/fixtures/${tid}?sid=${sid}&source=web&period=${period}&markets=${market}&specifier=${specifier}`
  );

export const loadCoupon = (code, action) =>
  Http.get(`sports/booking/${code}?action=${action}`);

export const todaysBet = () => Http.get("/user/account/today-bets");

export const findCoupon = (code) => Http.get(`sports/find-coupon/${code}`);

export const findBooking = (data) =>
  Http.post(`${process.env.REACT_APP_NEW_API}/bets/find-bet`, data);

export const login = (params) =>
  Http.post(`${process.env.REACT_APP_NEW_API}/auth/login?source=shop`, params);

export const sendLogout = () =>
  Http.get(`${process.env.REACT_APP_NEW_API}/auth/logout`);

export const authDetails = () =>
  Http.get(
    `${process.env.REACT_APP_NEW_API}/auth/details/${process.env.REACT_APP_CLIENT_ID}`
  );

export const getBetList = (data, page) =>
  Http.post(`${process.env.REACT_APP_NEW_API}/bets/history?page=${page}`, data);

export const register = (data) =>
  Http.post(`auth/register?client=website`, data);

export const sendVerification = (data) =>
  Http.post(`auth/send-verification-code`, data);

export const confirmVerification = (data) =>
  Http.post(`auth/confirm-verification-code`, data);

export const resetPassword = (data) => Http.post(`auth/reset-password`, data);

export const getAgentBetList = (data, page) =>
  Http.post(
    `/retail/${process.env.REACT_APP_CLIENT_ID}/betlist?page=${page}`,
    data
  );

export const getJackpotBetList = (data, page) =>
  Http.post(`/user/account/my-jackpot-bets?page=${page}`, data);

export const cancelTicket = (ticket) =>
  Http.get(`user/account/betslip/${ticket}/cancel`);

export const payoutTicket = (ticket) =>
  Http.get(`user/account/betslip/${ticket}/payout`);

export const getTransactions = (data, page, limit) =>
  Http.post(
    `${process.env.REACT_APP_NEW_API}/user/wallet/transactions?page=${page}&limit=${limit}`,
    data
  );

export const getBonusTransactions = (data, page) =>
  Http.post(`user/account/get-bonus-transactions?page=${page}`, data);

export const getBonuses = () => Http.get(`user/account/get-bonuses`);

export const redeemBonus = () =>
  Http.get(`user/account/redeem-bonus?source=website`);

export const getLoginHistory = (data, page) =>
  Http.post(`/user/account/login-history?page=${page}`, data);

export const getWithdrawalInfo = () => Http.get(`user/account/withdrawal-info`);

export const initializeTransaction = (data) =>
  Http.post("user/account/deposit?channel=shop", data);

export const getPersonalData = () => Http.get("/user/account/personal-data");

export const changePassword = (data) =>
  Http.put(`${process.env.REACT_APP_NEW_API}/auth/update/password`, data);

export const changeUserPassword = (data) =>
  Http.patch(
    `${process.env.REACT_APP_NEW_API}/auth/update/reset-password`,
    data
  );

export const postWithdrawal = (data) =>
  Http.post("user/account/withdraw", data);

export const getMarkets = (tid, sid, market_id, date = "") =>
  Http.get(
    `sports/get-odds/${tid}?sid=${sid}&market_id=${market_id}&date=${date}`
  );

export const findFixtures = (keyword) => Http.get(`sports/search?q=${keyword}`);

export const findFixture = (event_id) =>
  Http.get(`/sports/find-fixture/${event_id}`);

export const findFixtureWithOutcomes = (event_id) =>
  Http.get(
    `${process.env.REACT_APP_NEW_API}/sports/retail/fixture/${event_id}?timeoffset=+2`
  );

export const findCode = (data) => Http.post("/sports/find-code", data);

export const addUser = (data) =>
  Http.post("/user/account/retail/add-user", data);

export const getAllUsers = (id) =>
  Http.get(
    `${process.env.REACT_APP_NEW_API}/admin/retail/${process.env.REACT_APP_CLIENT_ID}/agent-users?agentId=${id}`
  );
export const sendFund = (data) =>
  Http.post(
    `${process.env.REACT_APP_NEW_API}/retail/${process.env.REACT_APP_CLIENT_ID}/fund-user`,
    data
  );

export const validateGames = (data) =>
  Http.post(
    `${process.env.REACT_APP_NEW_API}/sports/retail/validate-selections`,
    data
  );
export const allSalesReport = (data) =>
  Http.post(
    `${process.env.REACT_APP_NEW_API}/retail/${process.env.REACT_APP_CLIENT_ID}/reports/sales`,
    data
  );
export const sportsReport = ({ from, to }) =>
  Http.post(`/user/account/sports-report?from=${from}&to=${to}`);

export const salesReport = ({ from, to, product, id }) =>
  Http.post(
    `/user/account/sales-report?from=${from}&to=${to}&product=${product}&user_id=${id}`
  );
export const processCashout = (betslip_id) =>
  Http.get(`user/account/cashout/${betslip_id}`);

export const saleReport = ({ from, to, product_type }) =>
  Http.get(`/reports/sales?from=${from}&to=${to}&product_type=${product_type}`);

export const oddsLessThan = (data) => Http.post(`/sports/odds-less-than`, data);
export const commissionReports = ({ from, to }) =>
  Http.get(`/reports/commission?from=${from}&to=${to}`);

export const oddsLessThanFixtures = (data) =>
  Http.post(`/sports/odds-less-than/fixtures`, data);

export const processTransfer = ({ id, role }) =>
  Http.get(
    `${process.env.REACT_APP_NEW_API}/retail/${process.env.REACT_APP_CLIENT_ID}/wallet/deposit/transfer/${id}?role=${role}`
  );
export const validateDepositCode = (data) =>
  Http.post(
    `${process.env.REACT_APP_NEW_API}/retail/${process.env.REACT_APP_CLIENT_ID}/wallet/deposit/validate`,
    data
  );
export const validateUserDepositCode = (clientId, data) =>
  Http.post(
    `${process.env.REACT_APP_NEW_API}/retail/${process.env.REACT_APP_CLIENT_ID}/wallet/deposit/validate`,
    data
  );

export const tranferFundToUser = (data) =>
  Http.put(
    `${process.env.REACT_APP_NEW_API}/auth/initiate-transfer/${process.env.REACT_APP_CLIENT_ID}`,
    data
  );

export const changePin = (data) =>
  Http.put(`${process.env.REACT_APP_NEW_API}/auth/handle/pin/create`, data);

export const processUserTransfer = (id, clientId, role) =>
  Http.get(
    `${process.env.REACT_APP_NEW_API}/retail/${clientId}/wallet/deposit/transfer/${id}?role=${role}`
  );

export const saveNewAgent = (data) => Http.post("/save-new-agent", data);
export const getUserDetails = () =>
  Http.get(
    `${process.env.REACT_APP_NEW_API}/auth/details/${process.env.REACT_APP_CLIENT_ID}`
  );

export const getCombos = (couponData) =>
  Http.post("/sports/get-combos", { selections: couponData.selections });

export const getSplitProps = async (couponData) => {
  const res = await Http.post("/sports/get-split-props", {
    selections: couponData.selections,
  });
  couponData.noOfCombos = res.noOfCombos;
  couponData.minOdds = res.minOdds;
  couponData.maxOdds = res.maxOdds;
  couponData.maxBonus = res.maxBonus;
  couponData.minWin = res.minWin;
  couponData.maxWin = res.maxWin;

  return couponData;
};

export const getLiveFixtures = (sid = 0) =>
  Http.get(`${process.env.REACT_APP_NEW_API}/sports/highlight/live/${sid}`);

export const getLiveFixtureData = (eventId) =>
  Http.get(`sports/live/${eventId}/en`);

export const getUpcomingLive = () => Http.get("/sports/live/upcoming");

export const getOddsChange = (data) =>
  Http.post("/sports/live/oddschanged", data);

export const getJackpots = () => Http.get("/sports/jackpots");

export const sendDeposit = (data) =>
  Http.post("/user/account/send-deposit", data);

export const sendWithdrawal = (data) =>
  Http.post("/user/account/send-withdrawal", data);

export const getTipsters = () => Http.get("/sports/tipsters/all");

export const getTipsterBetslips = (user_id) =>
  Http.get(`/sports/tipsters/get-betslips/${user_id}`);

export const saveTipsterBet = (data) => Http.post("/sports/tipsters/add", data);

export const rebetTipster = (data) => Http.post("/sports/tipsters/rebet", data);

export const getPoolTickets = (data, page) =>
  Http.post(`/user/account/pool-tickets?page=${page}`, data);

export const getCouponTickets = (data, page) =>
  Http.post(`/user/account/coupon-tickets?page=${page}`, data);

export const verifyTransactions = () =>
  Http.get(
    `${process.env.REACT_APP_NEW_API}/user/wallet/cashbook/branch/verify-transaction?clientId=${process.env.REACT_APP_CLIENT_ID}`
  );

export const fetchReport = (date) =>
  Http.get(
    `${process.env.REACT_APP_NEW_API}/user/wallet/cashbook/fetch-report?clientId=${process.env.REACT_APP_CLIENT_ID}&date=${date}`
  );
export const handleReport = (data) =>
  Http.post(
    `${process.env.REACT_APP_NEW_API}/user/wallet/cashbook/handle-report/${process.env.REACT_APP_CLIENT_ID}`,
    data
  );

export const getSalesReport = (status) =>
  Http.get(`${process.env.REACT_APP_NEW_API}/user/wallet/cashbook/branch/sales-report?status=${status}&clientId=${process.env.REACT_APP_CLIENT_ID}
`);

export const getExpenses = () =>
  Http.get(
    `${process.env.REACT_APP_NEW_API}/user/wallet/cashbook/branch/expense/${process.env.REACT_APP_CLIENT_ID}`
  );

export const getExpense = (id) => Http.get(`/show/expense/${id}`);

// export const getExpensesType = () => Http.get(`/list/expense/type`);
export const getExpensesType = () =>
  Http.get(
    `${process.env.REACT_APP_NEW_API}/user/wallet/cashbook/expense-type`
  );

// export const postExpense = (data) => Http.post(`/create/expense`, data);
export const postExpense = (data) =>
  Http.post(
    `${process.env.REACT_APP_NEW_API}/user/wallet/cashbook/expense/${process.env.REACT_APP_CLIENT_ID}`,
    data
  );
export const putExpense = (id, data) =>
  Http.put(
    `${process.env.REACT_APP_NEW_API}/user/wallet/cashbook/expense/${id}`,
    data
  );

export const deleteExpense = (id, data) =>
  Http.delete(
    `${process.env.REACT_APP_NEW_API}/user/wallet/cashbook/expense/${id}`,
    data
  );

export const getCashOuts = () =>
  Http.get(
    `${process.env.REACT_APP_NEW_API}/user/wallet/cashbook/branch/cashout/${process.env.REACT_APP_CLIENT_ID}`
  );

export const getCashIn = () =>
  Http.get(
    `${process.env.REACT_APP_NEW_API}/user/wallet/cashbook/branch/cashin/${process.env.REACT_APP_CLIENT_ID}`
  );

export const verifyRequest = (data) =>
  Http.post(
    `${process.env.REACT_APP_NEW_API}/retail/${process.env.REACT_APP_CLIENT_ID}/wallet/withdraw/validate`,
    data
  );
export const verifyUserWithdrawalRequest = (data) =>
  Http.post(
    `${process.env.REACT_APP_NEW_API}/retail/${process.env.REACT_APP_CLIENT_ID}/wallet/withdraw/validate`,
    data
  );
export const shopWithdrawal = ({ id, role }) =>
  Http.get(
    `${process.env.REACT_APP_NEW_API}/retail/${process.env.REACT_APP_CLIENT_ID}/wallet/withdraw/transfer/${id}?role=${role}`
  );

export const processUserWithdrawal = ({ id, role }) =>
  Http.get(
    `${process.env.REACT_APP_NEW_API}/retail/${process.env.REACT_APP_CLIENT_ID}/wallet/withdraw/transfer/${id}?role=${role}`
  );

export const shopAddUser = (payload) =>
  Http.post(`/user/account/retail/add-user`, payload);

export const getShopUsers = () =>
  Http.get(`${process.env.REACT_APP_NEW_API}/user/account/retail/users`);

export const getElbetUsers = (user) =>
  Http.get(`/admin/agent-management/elbet-cashier?agent_id=${user}`);

export const generate = (data) =>
  Http.post(`${process.env.REACT_APP_NEW_API}/shop/cashbook/latest`, data);
export const lastApproved = (data) =>
  Http.get(
    `${process.env.REACT_APP_NEW_API}/user/wallet/cashbook/branch/last-approved?clientId=${process.env.REACT_APP_CLIENT_ID}`
  );
export const approveCashin = (data) =>
  Http.put(
    `${process.env.REACT_APP_NEW_API}/user/wallet/cashbook/approve/cashin`,
    data
  );
export const approveCashout = (data) =>
  Http.put(
    `${process.env.REACT_APP_NEW_API}/user/wallet/cashbook/approve/cashout`,
    data
  );

export const cashbooktoday = (data) => Http.post("/shop/cashbook/today", data);

export const generateLatest = (data) =>
  Http.post("/shop/cashbook/latest", data);

// export const generateLatestApproved = (data) =>
//   Http.post("/shop/cashbook", data);
export const getAllLivescore = () => Http.get(`/sportscore/today-event`);

export const getLivescore = () => Http.get(`/sportscore/sportscore-live`);

export const getSettledCommision = ({ from, to, type }) =>
  Http.get(`commissions/settled?from=${from}&to=${to}&type=${type}`);

export const getBalanceOverview = () =>
  Http.get("/user/account/balance-overview");

export const getMonthlyBonus = () =>
  Http.get("commissions/settled-bonus?month=1&year=2023");

export const getBranchSummary = () =>
  Http.get(`${process.env.REACT_APP_NEW_API}/shop/cashbook/summary`);
export const getTellerSummary = () =>
  Http.get(`${process.env.REACT_APP_NEW_API}/shop/cashbook/teller/summary`);

export const getCashiers = () => Http.get("admin/list/agents");

export const verifyTransaction = (data) =>
  Http.get(
    `${process.env.REACT_APP_NEW_API}/user/wallet/verify-payment?clientId=${process.env.REACT_APP_CLIENT_ID}&paymentChannel=${data.paymentChannel}&transactionRef=${data.trxRef}`
  );

export const getAllBanks = () =>
  Http.get(
    `${process.env.REACT_APP_NEW_API}/user/wallet/${process.env.REACT_APP_CLIENT_ID}/banks`
  );

export const getBankAccounts = () =>
  Http.get(`${process.env.REACT_APP_NEW_API}/user/wallet/bank-accounts`);

export const bankWithdrawal = (payload) =>
  Http.post(`${process.env.REACT_APP_NEW_API}/user/wallet/withdraw`, payload);

export const verifyBankAccount = (data) =>
  Http.post(
    `${process.env.REACT_APP_NEW_API}/user/wallet/verify-bank-account`,
    data
  );
